/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;font-size: 12px; }
.left-panel-login{
    background: linear-gradient(180deg, #A9CC6A -78.17%, #23573F 100%);         
}
.content-color{
    background-color: #efefef;
}

.table-custom{
    border-collapse: separate;
    border-spacing: 0;
}
.table-custom tbody tr{
    border: 1px solid #ccc;
    border-radius: 5px;
}